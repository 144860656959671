import { gql } from "@apollo/client";
import { StudentCountCell_TutorDashboardCohortFragment } from "@generated/graphql";
import { CellProps, Column } from "react-table";
import { InstructionalSupportTabDashboardEventDetails } from "../types";

studentCountCell.fragments = {
  tutorDashboardCohort: gql`
    fragment StudentCountCell_TutorDashboardCohort on TutorDashboardCohort {
      id
      activeStudentsCount
    }
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  unknown,
  StudentCountCell_TutorDashboardCohortFragment,
  unknown
>;

export function studentCountCell<D extends EventDetails>(): Column<D> {
  return {
    id: "studentCount",
    Header: "Students",
    Cell: ({
      row: {
        original: { cohort },
      },
    }: CellProps<D>) => <p>{cohort?.activeStudentsCount ?? 0}</p>,
  };
}
