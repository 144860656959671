import { Badge, Icon, Tooltip } from "components/shared";

export const noShowDayTooltip = (isEngagementNoShowDay: boolean) => (
  <Tooltip
    className="relative flex w-5"
    content={`This is a${
      isEngagementNoShowDay ? "n Engagement" : " Cohort"
    } student no-show day, so attendance is not recorded for this day.`}
  >
    <Badge className="flex bg-gray-200 text-gray-900 font-light text-2xs gap-1 uppercase">
      <Icon icon="student" size={5} color="text-gray-600" />
      {`${isEngagementNoShowDay ? "Engagement" : "Cohort"}`} no show day
    </Badge>
  </Tooltip>
);

export const noRecordBadge = (record: string) => (
  <Badge className="flex bg-amber-200 text-gray-900 font-light text-2xs gap-[4px] w-fit uppercase">
    <Icon icon="warning" size={4} color="text-gray-600" />
    {`No ${record} record generated`}
  </Badge>
);

export const notHappenedYetBadge = () => (
  <Badge className="flex bg-gray-200 text-gray-900 font-light text-2xs gap-[4px] w-fit">
    <Icon icon="sched" size={4} color="text-gray-600" />
    This session hasn&apos;t occurred yet!
  </Badge>
);
