import { gql } from "@apollo/client";
import { TutoringTabDashboardEventDetailsBuilder_TutorDashboardEventFragment } from "@generated/graphql";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { getEventScheduleStatus } from "../../utils";
import { TutorTabDashboardEventDetails } from "./types";

tutoringTabDashboardEventDetailsBuilder.fragments = {
  tutorDashboardEvent: gql`
    fragment TutoringTabDashboardEventDetailsBuilder_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      startDateTime
      endDateTime
      durationMinutes
    }
  `,
};

export function tutoringTabDashboardEventDetailsBuilder<
  D extends TutoringTabDashboardEventDetailsBuilder_TutorDashboardEventFragment,
  C,
  E,
>(
  ttDashboardEvent: D,
  cohort: C | undefined,
  engagement: E | undefined,
  currentDate: Date
): TutorTabDashboardEventDetails<D, C, E> {
  const startDateTime = new Date(ttDashboardEvent.startDateTime);
  const endDateTime = new Date(ttDashboardEvent.endDateTime);
  const minutesElapsed = differenceInMinutes(currentDate, startDateTime);
  return {
    ...ttDashboardEvent,
    cohort,
    engagement,
    scheduleStatus: getEventScheduleStatus(
      startDateTime,
      endDateTime,
      currentDate
    ),
    minutesElapsed,
    minutesRemaining: ttDashboardEvent.durationMinutes - minutesElapsed,
  };
}
