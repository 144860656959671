import { AccountRole, GetCurrentUserQuery } from "@generated/graphql";
import { Page } from "components/shared";
import { AttendanceDataProvider } from "contexts/AttendanceDataProvider";
import { TutorDashboardDataProvider } from "contexts/TutorDashboardDataProvider";
import { MentorDashboard } from "./MentorDashboard";
import { TutorDashboard } from "./TutorDashboard/TutorDashboard";

type Props = { currentUser: NonNullable<GetCurrentUserQuery["currentUser"]> };

export const UserDashboard = ({ currentUser }: Props) => (
  <Page className="gap-y-4 mb-32">
    {currentUser.accountRole === AccountRole.MentorTeacher ? (
      <AttendanceDataProvider>
        <MentorDashboard currentUser={currentUser} />
      </AttendanceDataProvider>
    ) : (
      <TutorDashboardDataProvider>
        <TutorDashboard currentUser={currentUser} />
      </TutorDashboardDataProvider>
    )}
  </Page>
);
