import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  AttendanceCell_TutorDashboardCohortSessionStudentAttendanceFragment,
  AttendanceCell_TutorDashboardStudentFragment,
  CohortSessionStudentAttendanceStatus,
  EditSingleStudentAttendanceMutation,
  EditSingleStudentAttendanceMutationVariables,
} from "@generated/graphql";
import { Icon, Tooltip, triggerErrorToast } from "components/shared";
import {
  AttendanceStatusButtonsPanel,
  DisplayNameSaveOnChangeInput,
} from "components/shared/AttendanceGrades";
import { NO_MATCH } from "components/shared/AttendanceGrades/constants";
import {
  isNotAttended,
  makeAttendanceKey,
} from "components/shared/AttendanceGrades/utils";
import { useTutorDashboardData } from "contexts/TutorDashboardDataProvider";
import { useState } from "react";
import { CellProps, Column } from "react-table";
import { TutorTabDashboardStudentDetailsRow } from "../../types";
import {
  noRecordBadge,
  noShowDayTooltip,
  notHappenedYetBadge,
} from "../helpers";
import { AttendanceNote } from "./components/AttendanceNote";

type OnSaveStudentAttendanceProps = {
  status?: CohortSessionStudentAttendanceStatus;
  displayName?: string | null;
  notes?: string;
};

attendanceCell.fragments = {
  tutorDashboardStudent: gql`
    fragment AttendanceCell_TutorDashboardStudent on TutorDashboardStudent {
      studentId
    }
  `,
  tutorDashboardCohortSessionStudentAttendance: gql`
    fragment AttendanceCell_TutorDashboardCohortSessionStudentAttendance on TutorDashboardCohortSessionStudentAttendance {
      id
      notes
      status
      displayName
    }
  `,
};

const EDIT_SINGLE_STUDENT_ATTENDANCE = gql`
  mutation EditSingleStudentAttendance(
    $input: [EditCohortSessionStudentAttendanceInput!]!
  ) {
    editCohortSessionStudentAttendance(input: $input) {
      id
      notes
      status
      displayName
    }
  }
`;

type CellData = TutorTabDashboardStudentDetailsRow<
  AttendanceCell_TutorDashboardStudentFragment,
  AttendanceCell_TutorDashboardCohortSessionStudentAttendanceFragment,
  unknown
>;

export function attendanceCell<C extends CellData>(): Column<C> {
  return {
    id: "attendanceCell",
    Header: "Attendance",
    Cell: ({
      row: {
        original: {
          student: { studentId },
          startDateTime,
          cohortSessionId,
          isCohortNoShowDay,
          isEngagementNoShowDay,
          studentAttendanceEntry,
        },
      },
    }: CellProps<C>) => {
      const {
        backupStudentNames,
        studentMatchData,
        activeNote,
        setActiveNote,
      } = useTutorDashboardData();

      const [editSingleStudentAttendanceMutation, { loading }] = useMutation<
        EditSingleStudentAttendanceMutation,
        EditSingleStudentAttendanceMutationVariables
      >(EDIT_SINGLE_STUDENT_ATTENDANCE, {
        update(cache, { data }) {
          if (data) {
            const updatedAttendance =
              data.editCohortSessionStudentAttendance[0];
            cache.modify({
              id: `TutorDashboardCohortSessionStudentAttendance:${updatedAttendance.id}`,
              fields: {
                status: () => updatedAttendance.status,
                notes: () => updatedAttendance.notes ?? null,
                displayName: () => updatedAttendance.displayName ?? null,
              },
            });
          }
        },
        onError: (error: ApolloError) =>
          triggerErrorToast({
            message: "Looks like something went wrong.",
            sub: "We weren't able to update this student's attendance successfully.",
            log: error,
          }),
      });

      const currentNote = studentAttendanceEntry?.notes || null;
      const attendanceKey = makeAttendanceKey(cohortSessionId, studentId);
      const hasNote = activeNote === attendanceKey || !!currentNote;
      const [showNote, setShowNote] = useState<boolean>(hasNote);
      const studentDisplayNameMatchData = studentMatchData[attendanceKey];
      const disabled = studentAttendanceEntry?.status
        ? isNotAttended(studentAttendanceEntry.status)
        : true;

      if (!studentAttendanceEntry) {
        if (startDateTime > new Date()) return notHappenedYetBadge();
        return noRecordBadge("attendance");
      }

      const onAttendanceSave = async ({
        status,
        notes,
        displayName,
      }: OnSaveStudentAttendanceProps) => {
        let nameUpdate = displayName || undefined;
        const statusToAway = status && !disabled && isNotAttended(status);
        const statusToPresent = status && disabled && !isNotAttended(status);
        if (statusToPresent) nameUpdate = backupStudentNames[attendanceKey];
        if (statusToAway) nameUpdate = NO_MATCH;
        if (notes === undefined) setActiveNote("");

        await editSingleStudentAttendanceMutation({
          variables: {
            input: [
              {
                id: studentAttendanceEntry.id,
                notes,
                status,
                studentId,
                cohortSessionId,
                displayName: nameUpdate,
              },
            ],
          },
        });
      };

      return isEngagementNoShowDay || isCohortNoShowDay ? (
        noShowDayTooltip(isEngagementNoShowDay)
      ) : (
        <div className="flex flex-col items-center justify-center w-fit">
          <div className="flex w-full h-full items-center justify-start gap-x-3">
            <AttendanceStatusButtonsPanel
              updateStudentAttendance={(status) => onAttendanceSave({ status })}
              status={studentAttendanceEntry.status}
            />
            <DisplayNameSaveOnChangeInput
              attendanceId={attendanceKey}
              saveName={(displayName) => onAttendanceSave({ displayName })}
              matchData={studentDisplayNameMatchData || undefined}
              savedDisplayName={studentAttendanceEntry.displayName}
              disabled={disabled}
            />
            <Tooltip content="Leave a note">
              <span
                onClick={() => {
                  setActiveNote(showNote ? "" : attendanceKey);
                  setShowNote(!showNote);
                }}
                className="flex cursor-pointer group items-center justify-start text-sm"
              >
                <Icon
                  icon="note"
                  size={8}
                  color="text-slate-700 group-hover:text-blue-500"
                />
              </span>
            </Tooltip>
          </div>
          <AttendanceNote
            note={currentNote}
            onUpdateNote={(notes) => onAttendanceSave({ notes })}
            loading={loading}
            attendanceId={attendanceKey}
            showNote={showNote}
          />
        </div>
      );
    },
  };
}
