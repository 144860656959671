import { gql } from "@apollo/client";
import {
  TutoringTabCohortEventPanel_TutorDashboardCohortFragment,
  TutoringTabCohortEventPanel_TutorDashboardEngagementFragment,
  TutoringTabCohortEventPanel_TutorDashboardEventFragment,
} from "@generated/graphql";
import { getOrigin } from "@utils/browser";
import { getHostShortMeetingLink } from "@utils/roomUrls";
import { Routes } from "@utils/routes";
import { formatArrayOfGrades } from "@utils/strings";
import {
  Button,
  CohortAssignmentSubjectBadge,
  Container,
  IconText,
  Link,
} from "components/shared";
import { EmptyStateContainer } from "components/shared/EmptyStateContainer";
import { EventTimeDetails } from "sections/UserDashboard/components/EventTimeDetails";
import { EventScheduleStatus } from "types/events";
import { TutorTabDashboardEventDetails } from "../types";
import { TutorTabCohortEventTable } from "./TutorTabCohortEventTable";

TutorTabCohortEventPanel.fragments = {
  tutorDashboardEvent: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      durationMinutes
      subject
      subSubject
      startDateTime
      endDateTime
      cohortId
      engagementId
      cohortSession {
        id
      }
      ...TutorTabCohortEventTable_TutorDashboardEvent
    }
    ${TutorTabCohortEventTable.fragments.tutorDashboardEvent}
  `,
  tutorDashboardCohort: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardCohort on TutorDashboardCohort {
      id
      name
      grade
      publicReferenceId
      activeStudentsCount
      ...TutorTabCohortEventTable_TutorDashboardCohort
    }
    ${TutorTabCohortEventTable.fragments.tutorDashboardCohort}
  `,
  tutorDashboardEngagement: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      name
      videoProvider
      ...TutorTabCohortEventTable_TutorDashboardEngagement
    }
    ${TutorTabCohortEventTable.fragments.tutorDashboardEngagement}
  `,
};

type Props = {
  eventDetail: TutorTabDashboardEventDetails<
    TutoringTabCohortEventPanel_TutorDashboardEventFragment,
    TutoringTabCohortEventPanel_TutorDashboardCohortFragment,
    TutoringTabCohortEventPanel_TutorDashboardEngagementFragment
  >;
};

export function TutorTabCohortEventPanel({ eventDetail }: Props) {
  // This shouldn't happen, but asserting it otherwise would be a hack.
  if (!eventDetail.engagement || !eventDetail.cohort) return null;

  const cohortHasActiveStudents = eventDetail.cohort.activeStudentsCount > 0;
  return (
    <Container
      key={eventDetail.cacheKey}
      className="flex flex-col gap-2 w-full overflow-visible z-auto"
    >
      <EventTimeDetails
        startTime={eventDetail.startDateTime}
        endTime={eventDetail.endDateTime}
        minutesRemaining={eventDetail.minutesRemaining}
        durationMinutes={eventDetail.durationMinutes}
        minutesElapsed={eventDetail.minutesElapsed}
        conclusion="This cohort session is now over."
        status={eventDetail.scheduleStatus}
      />
      <div className="relative flex justify-between items-center overflow-visible">
        <div className="flex gap-5 items-center text-lg">
          <IconText
            icon="engagement"
            child={
              <Link
                route={Routes.engagementsDashboard}
                routeProps={[`engagementId=${eventDetail.engagementId}`]}
              >
                {eventDetail.engagement.name}
              </Link>
            }
          />
          <IconText
            icon="cohort"
            child={
              <Link
                route={Routes.engagementsDashboard}
                routeProps={[
                  `engagementId=${eventDetail.engagementId}&cohortId=${eventDetail.cohortId}`,
                ]}
              >
                {eventDetail.cohort.name}
              </Link>
            }
          />
          {eventDetail.cohort.grade && (
            <IconText
              icon="grade"
              child={formatArrayOfGrades(eventDetail.cohort.grade, true)}
            />
          )}
          <CohortAssignmentSubjectBadge
            cohortSubject={eventDetail.subject}
            cohortSubSubject={eventDetail.subSubject}
          />
        </div>
        <Link
          href={getHostShortMeetingLink(
            getOrigin(),
            eventDetail.cohort.publicReferenceId,
            eventDetail.engagement.videoProvider
          )}
          className="h-30"
          target="_blank"
        >
          <Button className="h-7 leading-3 mb-1 px-6" theme="primary">
            Join Meeting
          </Button>
        </Link>
      </div>

      {cohortHasActiveStudents ? (
        <TutorTabCohortEventTable eventDetails={eventDetail} />
      ) : (
        <EmptyStateContainer
          title={
            eventDetail.scheduleStatus === EventScheduleStatus.UPCOMING
              ? "This session hasn't occurred yet."
              : eventDetail.scheduleStatus === EventScheduleStatus.ONGOING
              ? "This session is in progress."
              : "This session has concluded."
          }
          showIcon={false}
          className="gap-1 py-3 px-5"
          subtitle={<p>No students have been assigned to this session.</p>}
        />
      )}
    </Container>
  );
}
