import {
  CohortSessionTeacherAttendanceStatus,
  EngagementInstructionalSupportAttendanceStatus,
} from "@generated/graphql";

export interface AttendanceStatusOption<AttendanceStatus extends string> {
  status: AttendanceStatus;
  title: string;
}

export const teacherAttendanceStatusOptions: AttendanceStatusOption<CohortSessionTeacherAttendanceStatus>[] =
  [
    {
      status: CohortSessionTeacherAttendanceStatus.Present,
      title: "Present",
    },
    {
      status: CohortSessionTeacherAttendanceStatus.Absent,
      title: "Absent",
    },
  ];

export const instructionalSupportAttendanceStatusOptions: AttendanceStatusOption<EngagementInstructionalSupportAttendanceStatus>[] =
  [
    {
      status: EngagementInstructionalSupportAttendanceStatus.Present,
      title: "Present",
    },
    {
      status: EngagementInstructionalSupportAttendanceStatus.Absent,
      title: "Absent",
    },
  ];
