import { gql } from "@apollo/client";
import {
  DashboardAssignmentRole,
  HostPresenceCell_DashboardStaffFragment,
} from "@generated/graphql";
import { destructureCacheKey } from "@utils/strings";
import { Badge, HostAttendanceIndicator, Tooltip } from "components/shared";
import { addMinutes } from "date-fns";
import { CellProps, Column } from "react-table";

hostPresenceCell.fragments = {
  staff: gql`
    fragment HostPresenceCell_DashboardStaff on DashboardStaff {
      role
      cohortId
      details {
        isNoShow
      }
    }
  `,
};

export function hostPresenceCell<
  Fragment extends HostPresenceCell_DashboardStaffFragment,
>(
  engagement: { tracksLiveAttendance: boolean },
  timeslot: string
): Column<Fragment> {
  return {
    Header: "Host Presence",
    Cell: ({ row: { original } }: CellProps<Fragment>) => {
      if (original.role === DashboardAssignmentRole.Is) return <div></div>;

      const [startTime, duration] = destructureCacheKey(timeslot);
      const sessionStartTime = new Date(Number(startTime));
      const sessionEndTime = addMinutes(sessionStartTime, Number(duration));

      const badgeNoShow = original?.details?.isNoShow && (
        <Tooltip content="This cohort has been identified as a no-show day.">
          <div className="text-center">
            <Badge className="bg-purple-200 text-purple-900 text-center font-light text-2xs mt-2">
              NO SHOW
            </Badge>
          </div>
        </Tooltip>
      );

      const attendanceIndicator = engagement.tracksLiveAttendance &&
        original.cohortId && (
          <HostAttendanceIndicator
            cohortId={original.cohortId}
            cohortSessionStartTime={sessionStartTime}
            cohortSessionEndTime={sessionEndTime}
          />
        );

      if (!engagement.tracksLiveAttendance || !original.cohortId)
        return (
          <Badge className="bg-gray-200 text-gray-900 text-center font-light text-2xs mt-2">
            UNAVAILABLE
          </Badge>
        );

      return (
        <div className="flex items-center space-x-2">
          {badgeNoShow}
          {attendanceIndicator}
        </div>
      );
    },
  };
}
