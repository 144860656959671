import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AttendanceModalFetchAttendanceQuery,
  AttendanceModalFetchAttendanceQueryVariables,
  AttendanceModalUpdateAttendanceMutation,
  AttendanceModalUpdateAttendanceMutationVariables,
  CohortAssignmentRole,
  CohortSessionTeacherAttendanceStatus,
  SubstituteTeacherSelector_IsAttendanceFragment,
  SubstituteTeacherSelector_IsAttendanceFragmentDoc,
} from "@generated/graphql";
import {
  AttendanceRobot,
  ErrorBox,
  Modal,
  StickyNoteInput,
  SubstituteTeacherSelector,
} from "components/shared";
import { useEffect, useState } from "react";
import { AttendanceStatusInput } from "sections/EngagementsDashboard/components/AttendanceRow";
import { teacherAttendanceStatusOptions } from "sections/EngagementsDashboard/components/AttendanceRow/constants";
import { useDebounce } from "use-debounce";

const TEACHER_ATTENDANCE_QUERY = gql`
  query AttendanceModalFetchAttendance($ids: [ID!]!) {
    teacherAttendanceEntries(ids: $ids) {
      id
      notes
      status
      updatedById
      substituteId
      cohortSession {
        id
        availableSubstitutes {
          ...SubstituteTeacherSelector_ISAttendance
        }
      }
    }
  }
  ${SubstituteTeacherSelector_IsAttendanceFragmentDoc}
`;

const TEACHER_ATTENDANCE_MUTATION = gql`
  mutation AttendanceModalUpdateAttendance(
    $input: EditCohortSessionTeacherAttendanceInput!
  ) {
    editCohortSessionTeacherAttendance(input: $input) {
      id
    }
  }
`;

type Props = {
  show: boolean;
  teacherName: string;
  attendanceId: string;
  closeModal: () => void;
};

export function AttendanceModal({
  show,
  closeModal,
  teacherName,
  attendanceId,
}: Props) {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [attendanceNotes, setAttendanceNotes] = useState<string | null>("");
  const [attendanceStatus, setAttendanceStatus] =
    useState<CohortSessionTeacherAttendanceStatus>(
      CohortSessionTeacherAttendanceStatus.Unknown
    );
  const [automaticallyUpdated, setAutomaticallyUpdated] =
    useState<boolean>(false);
  const [stateChanged, setStateChanged] = useState(false);
  const [debouncedNotes, { isPending }] = useDebounce(attendanceNotes, 1000);
  const debouncePending = isPending();
  const [substituteId, setSubstituteId] = useState<string | null>(null);
  const [substituteAttendance, setSubstituteAttendance] = useState<
    SubstituteTeacherSelector_IsAttendanceFragment[]
  >([]);

  const [editTeacherAttendanceMutation, { loading: mutationLoading }] =
    useMutation<
      AttendanceModalUpdateAttendanceMutation,
      AttendanceModalUpdateAttendanceMutationVariables
    >(TEACHER_ATTENDANCE_MUTATION);

  useQuery<
    AttendanceModalFetchAttendanceQuery,
    AttendanceModalFetchAttendanceQueryVariables
  >(TEACHER_ATTENDANCE_QUERY, {
    variables: { ids: [String(attendanceId)] },
    onCompleted: (data) => {
      const {
        notes,
        status,
        updatedById,
        substituteId,
        cohortSession: { availableSubstitutes },
      } = data.teacherAttendanceEntries[0];

      setAttendanceNotes(notes || null);
      setAttendanceStatus(status);
      setStateChanged(false);
      setSubstituteAttendance(availableSubstitutes);
      setSubstituteId(substituteId || null);
      setAutomaticallyUpdated(
        updatedById === null &&
          status !== CohortSessionTeacherAttendanceStatus.Unknown
      );
    },
    onError: (error) => {
      console.error(error);
      setErrorMsg("Error loading attendance. Contact support.");
    },
    skip: !attendanceId,
  });

  useEffect(() => {
    if (!stateChanged) return;
    if (!attendanceId) return;

    editTeacherAttendanceMutation({
      variables: {
        input: {
          id: String(attendanceId),
          notes: debouncedNotes,
          status: attendanceStatus,
          assignmentRole: CohortAssignmentRole.PrimaryTeacher,
          substituteId,
        },
      },
      refetchQueries: ["AttendanceModalFetchAttendance"],
    });
  }, [
    debouncedNotes,
    attendanceStatus,
    substituteId,
    stateChanged,
    attendanceId,
    editTeacherAttendanceMutation,
  ]);

  return (
    <Modal show={show} onClose={closeModal} title={teacherName} width="medium">
      <div className="w-full max-w-lg min-w-xs gap-2 flex flex-col items-stretch">
        <StickyNoteInput
          value={attendanceNotes}
          onChange={(value) => {
            setAttendanceNotes(value);
            setStateChanged(true);
          }}
          debouncePending={debouncePending}
          mutationLoading={mutationLoading}
          placeholder="Add your note..."
          rows={2}
        />
        <div className="self-end justify-center items-center flex gap-6">
          <AttendanceRobot isAutomaticallyUpdated={automaticallyUpdated} />
          <AttendanceStatusInput
            attendanceRowId={attendanceId}
            status={attendanceStatus}
            onChange={(status) => {
              setAutomaticallyUpdated(false);
              setAttendanceStatus(status);
              setStateChanged(true);
            }}
            attendanceStatusOptions={teacherAttendanceStatusOptions}
            resetStatus={CohortSessionTeacherAttendanceStatus.Unknown}
          />
          {attendanceStatus === CohortSessionTeacherAttendanceStatus.Absent && (
            <SubstituteTeacherSelector
              isAttendanceEntries={substituteAttendance}
              selectedSubstituteId={substituteId}
              onSelectSubstitute={(newSubstituteId) => {
                if (substituteId === newSubstituteId) return;

                setSubstituteId(newSubstituteId ?? null);
                setStateChanged(true);
              }}
            />
          )}
        </div>

        <ErrorBox msg={errorMsg} />

        <Modal.Buttons>
          <Modal.Button
            type="cancel"
            onClick={closeModal}
            disabled={mutationLoading}
          >
            Close
          </Modal.Button>
        </Modal.Buttons>
      </div>
    </Modal>
  );
}
