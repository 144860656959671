import { gql } from "@apollo/client";
import {
  HostPresenceCell_TutorDashboardEngagementFragment,
  HostPresenceCell_TutorDashboardEventFragment,
} from "@generated/graphql";
import { Badge, HostAttendanceIndicator, Tooltip } from "components/shared";
import { CellProps, Column } from "react-table";
import { InstructionalSupportTabDashboardEventDetails } from "../types";

hostPresenceCell.fragments = {
  tutorDashboardEvent: gql`
    fragment HostPresenceCell_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      startDateTime
      endDateTime
      isStudentExclusionDay
      cohortSessionId
      cohortId
    }
  `,
  tutorDashboardEngagement: gql`
    fragment HostPresenceCell_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      tracksLiveAttendance
    }
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  HostPresenceCell_TutorDashboardEventFragment,
  unknown,
  HostPresenceCell_TutorDashboardEngagementFragment
>;

export function hostPresenceCell<D extends EventDetails>(): Column<D> {
  return {
    id: "hostPresenceCell",
    Header: "Host Presence",
    Cell: ({
      row: {
        original: {
          cohortId,
          startDateTime,
          endDateTime,
          isStudentExclusionDay,
          engagement,
          cohortSessionId,
        },
      },
    }: CellProps<D>) => {
      if (!engagement?.tracksLiveAttendance || !cohortSessionId)
        return (
          <Badge className="bg-gray-200 text-gray-900 text-center font-light text-2xs mt-2">
            UNAVAILABLE
          </Badge>
        );

      const badgeNoShow = isStudentExclusionDay && (
        <Tooltip content="This cohort has been identified as a non-calculated student day.">
          <div className="text-center">
            <Badge className="bg-purple-200 text-purple-900 font-light text-center text-2xs mt-2">
              NON-CALCULATED STUDENT DAY
            </Badge>
          </div>
        </Tooltip>
      );

      const attendanceIndicator = engagement.tracksLiveAttendance && (
        <HostAttendanceIndicator
          cohortId={cohortId}
          cohortSessionStartTime={new Date(startDateTime)}
          cohortSessionEndTime={new Date(endDateTime)}
        />
      );

      return (
        <div className="flex items-center space-x-2">
          {badgeNoShow}
          {attendanceIndicator}
        </div>
      );
    },
  };
}
