import { gql } from "@apollo/client";
import {
  NameCell_TutorDashboardStudentFragment,
  StudentSortableField,
  StudentStatus,
} from "@generated/graphql";
import { Routes } from "@utils/routes";
import { Icon, Link, Tooltip } from "components/shared";
import { getStudentStatusTooltip } from "components/shared/AttendanceGrades/helpers";
import { CellProps, Column } from "react-table";
import { TutorTabDashboardStudentDetailsRow } from "../types";

nameCell.fragments = {
  tutorDashboardStudent: gql`
    fragment NameCell_TutorDashboardStudent on TutorDashboardStudent {
      id
      fullName
      startDate
      studentStatus
    }
  `,
};

type CellData = TutorTabDashboardStudentDetailsRow<
  NameCell_TutorDashboardStudentFragment,
  unknown,
  unknown
>;

export function nameCell<C extends CellData>(): Column<C> {
  return {
    id: StudentSortableField.FullName,
    Header: "Name",
    Cell: ({
      row: {
        original: {
          student: { fullName, studentStatus, startDate },
        },
      },
    }: CellProps<C>) => (
      <Tooltip
        disabled={studentStatus === StudentStatus.Fixture}
        tooltipProps={{ place: "right" }}
        className="flex items-center gap-1 cursor-pointer"
        content={getStudentStatusTooltip(
          studentStatus ?? StudentStatus.New,
          startDate
        )}
      >
        <Link
          route={Routes.engagementsDashboard}
          routeProps={[`search=${fullName}`]}
        >
          {fullName}
        </Link>
        {studentStatus !== StudentStatus.Fixture && (
          <Icon
            icon="new"
            size={4}
            color={
              studentStatus === StudentStatus.New
                ? "text-yellow-500"
                : "text-indigo-500"
            }
          />
        )}
      </Tooltip>
    ),
  };
}
