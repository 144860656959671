import { MentorDashboardQuery } from "@generated/graphql";
import { Routes } from "@utils/routes";
import { makeCacheKey } from "@utils/strings";
import { IconText, Link, Table } from "components/shared";
import { Column } from "react-table";
import {
  attendanceCell,
  detailsCell,
  hostPresenceCell,
  nameCell,
  roleCell,
} from "./cells";

type Props = {
  engagement: MentorDashboardQuery["getMentorDashboard"][0]["engagements"][0];
  setSelectedUserId: (userId: string) => void;
  setOpenAttendanceScorecard: (open: boolean) => void;
  setOpenAttendanceModal: (open: boolean) => void;
  setAttendanceId: (id: string) => void;
  setTeacherName: (teacherName: string) => void;
  duration: number;
  startTime: Date;
};

export const MentorDashboardEngagement = ({
  engagement,
  duration,
  startTime,
  setSelectedUserId,
  setOpenAttendanceScorecard,
  setOpenAttendanceModal,
  setAttendanceId,
  setTeacherName,
}: Props) => (
  <div className="flex flex-col gap-2">
    <div className="flex text-lg">
      <IconText
        icon="engagement"
        child={
          <Link
            route={Routes.engagementsDashboard}
            routeProps={[`engagementId=${engagement.engagementId}`]}
          >
            {engagement.name}
          </Link>
        }
      />
    </div>
    <Table
      showPagination={false}
      columns={getColumns(
        engagement,
        makeCacheKey(startTime.getTime(), duration),
        setSelectedUserId,
        setOpenAttendanceScorecard,
        setOpenAttendanceModal,
        setAttendanceId,
        setTeacherName
      )}
      className="min-h-0"
      data={engagement.staff}
    />
  </div>
);

type StaffTrackerRow =
  MentorDashboardQuery["getMentorDashboard"][0]["engagements"][0]["staff"][0];

const getColumns = (
  engagement: MentorDashboardQuery["getMentorDashboard"][0]["engagements"][0],
  timeslot: string,
  setSelectedUserId: (userId: string) => void,
  setOpenAttendanceScorecard: (open: boolean) => void,
  setOpenAttendanceModal: (open: boolean) => void,
  setAttendanceId: (id: string) => void,
  setTeacherName: (teacherName: string) => void
) => {
  const columns: Column<StaffTrackerRow>[] = [
    nameCell(setSelectedUserId, setOpenAttendanceScorecard),
    roleCell(),
    hostPresenceCell(engagement, timeslot),
    attendanceCell(setOpenAttendanceModal, setAttendanceId, setTeacherName),
    detailsCell(engagement, timeslot),
  ];
  return columns;
};
