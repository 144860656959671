import { gql, useQuery } from "@apollo/client";
import {
  GetCurrentUserQuery,
  TutorDashboardDataQuery,
  TutorDashboardDataQueryVariables,
} from "@generated/graphql";
import {
  APP_DEFAULT_TIME_ZONE,
  ISODate,
  MINUTE_MS,
  normalizeToLocalISODate,
  normalizeToZonedISODate,
} from "@utils/dateTime";
import { getInputStyle } from "@utils/styleStrings";
import { useInterval } from "@utils/useInterval";
import { DatePicker } from "antd";
import clsx from "clsx";
import { triggerErrorToast } from "components/shared";
import { OptionsToggleButton } from "components/shared/Buttons/OptionsToggleButton";
import dayjs from "dayjs";
import "react";
import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { InstructionalSupportTab } from "./components/InstructionalSupportTab/InstructionalSupportTab";
import { TutoringTab } from "./components/TutoringTab/TutoringTab";

export enum TutorDashTab {
  TT = "Tutoring",
  IS = "Instructional Support",
}

export const GET_TUTOR_DASHBOARD_DATA_QUERY = gql`
  query TutorDashboardData($userId: ID!, $selectedISODateET: String!) {
    getTutorDashboardData(
      userId: $userId
      selectedISODateET: $selectedISODateET
    ) {
      tutoringTabEvents {
        cacheKey
        ...TutoringTab_TutorDashboardEvent
      }
      instructionalSupportTabEvents {
        cacheKey
        ...InstructionalSupportTab_TutorDashboardEvent
      }
      cohorts {
        id
        ...TutoringTab_TutorDashboardCohort
        ...InstructionalSupportTab_TutorDashboardCohort
      }
      engagements {
        id
        ...TutoringTab_TutorDashboardEngagement
        ...InstructionalSupportTab_TutorDashboardEngagement
      }
      tutoringTabEventsCount
      instructionalSupportTabEventsCount
    }
  }
  ${TutoringTab.fragments.tutorDashboardEvent}
  ${InstructionalSupportTab.fragments.tutorDashboardEvent}
  ${TutoringTab.fragments.tutorDashboardCohort}
  ${InstructionalSupportTab.fragments.tutorDashboardCohort}
  ${TutoringTab.fragments.tutorDashboardEngagement}
  ${InstructionalSupportTab.fragments.tutorDashboardEngagement}
`;

type Props = {
  currentUser: NonNullable<GetCurrentUserQuery["currentUser"]>;
};

export function TutorDashboard({ currentUser }: Props) {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [debouncedActiveTab] = useDebounce(activeTab, 250);
  const [currentDate, setCurrentDate] = useState(new Date());

  const currentISODateET: ISODate = useMemo(
    () => normalizeToZonedISODate(currentDate, APP_DEFAULT_TIME_ZONE),
    [currentDate]
  );
  const [selectedISODateET, setSelectedISODateET] =
    useState<ISODate>(currentISODateET);
  const isCustomDate = selectedISODateET !== currentISODateET;

  useInterval(() => setCurrentDate(new Date()), MINUTE_MS);

  const { data, loading } = useQuery<
    TutorDashboardDataQuery,
    TutorDashboardDataQueryVariables
  >(GET_TUTOR_DASHBOARD_DATA_QUERY, {
    variables: {
      userId: currentUser.id,
      selectedISODateET,
    },
    fetchPolicy: "network-only",
    onError: (error) =>
      triggerErrorToast({
        message: "Looks like something went wrong.",
        sub: "We weren't able to fetch this Dashboard data.",
        log: error,
      }),
  });

  const getIndex = (value: TutorDashTab): number =>
    Object.values(TutorDashTab).indexOf(value);

  const counts = data?.getTutorDashboardData
    ? [
        data.getTutorDashboardData.tutoringTabEventsCount,
        data.getTutorDashboardData.instructionalSupportTabEventsCount,
      ]
    : [0, 0];

  return (
    <>
      <div className="flex w-full justify-between items-center flex-col sm:flex-row gap-x-4">
        <OptionsToggleButton
          options={Object.values(TutorDashTab)}
          counts={counts}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          loading={loading}
        />
        <div
          className={clsx(
            "flex items-center gap-x-3 min-w-[230px] h-14",
            "px-4 rounded-[12px] shadow-options-outer",
            isCustomDate ? "bg-slate-600" : "bg-white"
          )}
        >
          <label
            className={clsx(
              "text-sm font-medium whitespace-nowrap overflow-hidden",
              isCustomDate ? "text-white" : "text-slate-700"
            )}
          >
            {isCustomDate ? "Custom" : "Today"}
          </label>
          <DatePicker
            onChange={(date) => {
              date &&
                setSelectedISODateET(normalizeToLocalISODate(date.toDate()));
            }}
            defaultValue={dayjs(currentISODateET)}
            format={"MM/DD/YYYY"}
            showNow={false}
            style={getInputStyle}
            className="flex-1"
          />
        </div>
      </div>
      {debouncedActiveTab === getIndex(TutorDashTab.TT) && (
        <TutoringTab
          ttDashboardEvents={
            data?.getTutorDashboardData?.tutoringTabEvents ?? []
          }
          cohorts={data?.getTutorDashboardData?.cohorts ?? []}
          engagements={data?.getTutorDashboardData?.engagements ?? []}
          loading={loading}
        />
      )}
      {debouncedActiveTab === getIndex(TutorDashTab.IS) && (
        <InstructionalSupportTab
          isDashboardEvents={
            data?.getTutorDashboardData?.instructionalSupportTabEvents ?? []
          }
          cohorts={data?.getTutorDashboardData?.cohorts ?? []}
          engagements={data?.getTutorDashboardData?.engagements ?? []}
          loading={loading}
        />
      )}
    </>
  );
}
