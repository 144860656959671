import {
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
} from "@apollo/client";
import {
  AccountStatus,
  CohortSessionTeacherAttendanceStatus,
} from "@generated/graphql";
import { AttendanceRobot } from "components/shared";
import { DynamicTextArea } from "components/shared/Inputs/DynamicTextArea";
import { StaffAssignmentAvatar } from "components/staffAssignments/StaffAssignmentAvatar";
import { useAuth } from "contexts/AuthProvider";
import { useEffect, useState } from "react";
import { AttendanceRowData } from "sections/EngagementsDashboard/types";
import { useDebounce } from "use-debounce";
import { AttendanceStatusInput, TeacherSurveyLinks } from "./components";
import { AttendanceStatusOption as AttendanceStatusOptionInterface } from "./constants";

type OnMutateFunction<TData, TVariables> = (
  options?: MutationFunctionOptions<TData, TVariables>
) => Promise<FetchResult<TData>>;

interface Props<
  AttendanceStatus extends string,
  AttendanceStatusOption extends
    AttendanceStatusOptionInterface<AttendanceStatus>,
  TData,
  TVariables = OperationVariables,
> {
  setChangesPending: (changesPending: boolean) => void;
  attendanceRow: AttendanceRowData<AttendanceStatus>;
  onMutate: OnMutateFunction<TData, TVariables>;
  mutationLoading: boolean;
  attendanceStatusOptions: AttendanceStatusOption[];
  resetStatus?: AttendanceStatus;
}

export function AttendanceRow<
  AttendanceStatus extends string,
  AttendanceStatusOption extends
    AttendanceStatusOptionInterface<AttendanceStatus>,
  TData,
  TVariables,
>({
  setChangesPending,
  mutationLoading,
  attendanceRow: {
    id,
    attendanceStatus,
    notes,
    userName,
    userId,
    userEmail,
    userAccountStatus,
    userAccountRole,
    cohortId,
    updatedById,
    cohortSessionId,
    cohortAssignmentRole,
    statusConditionalElementFunc,
  },
  onMutate,
  attendanceStatusOptions,
  resetStatus,
}: Props<AttendanceStatus, AttendanceStatusOption, TData, TVariables>) {
  const { isTeacher } = useAuth();
  const [draftNotes, setDraftNotes] = useState(notes);
  const [draftStatus, setDraftStatus] = useState(attendanceStatus);
  const [debouncedDraftNotes, { isPending: notesIsPending }] = useDebounce(
    draftNotes,
    1000
  );
  const [debouncedDraftStatus, { isPending: statusIsPending }] = useDebounce(
    draftStatus,
    150
  );

  const debouncePending = notesIsPending() || statusIsPending();

  useEffect(() => {
    if (
      debouncedDraftNotes !== notes ||
      debouncedDraftStatus !== attendanceStatus
    ) {
      onMutate({
        variables: {
          input: {
            id: id,
            status: debouncedDraftStatus,
            assignmentRole: cohortAssignmentRole,
            notes: debouncedDraftNotes === "" ? null : debouncedDraftNotes,
          },
        } as TVariables,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cohortAssignmentRole,
    debouncedDraftNotes,
    debouncedDraftStatus,
    id,
    onMutate,
    // Leaving `attendanceStatus` and `notes` out to prevent unwanted mutation calls.
  ]);

  useEffect(() => setDraftStatus(attendanceStatus), [attendanceStatus]);
  useEffect(() => setDraftNotes(notes), [notes]);
  useEffect(() => {
    setChangesPending(mutationLoading || debouncePending);
  }, [mutationLoading, debouncePending, setChangesPending]);

  const getAvatarColor =
    attendanceStatus === CohortSessionTeacherAttendanceStatus.Present
      ? "bg-emerald-500"
      : attendanceStatus === CohortSessionTeacherAttendanceStatus.Absent
      ? "bg-purple-500"
      : "bg-slate-200";

  return (
    <div className="flex flex-col w-full gap-2 mt-4">
      <div className="flex flex-row justify-between space-x-4">
        <div className="sm:shrink-0 flex-1 grow">
          <StaffAssignmentAvatar
            id={userId}
            fullName={userName ?? ""}
            accountRole={userAccountRole}
            email={userEmail ?? ""}
            className={getAvatarColor}
            cohortAssignmentRole={cohortAssignmentRole}
            isInactive={userAccountStatus === AccountStatus.Inactive}
          />
          {isTeacher && cohortId && cohortSessionId && (
            <TeacherSurveyLinks
              revieweeId={userId}
              cohortId={cohortId}
              sessionId={cohortSessionId}
            />
          )}
        </div>
        <div className="w-full max-w-lg min-w-xs">
          <DynamicTextArea
            value={draftNotes}
            onChange={(value) => setDraftNotes(value)}
            debouncePending={debouncePending}
            mutationLoading={mutationLoading}
            placeholder="Add your note..."
            textAreaClassName="border-gray-400"
            rows={2}
          />
        </div>
      </div>
      <div className="flex flex-row-reverse flex-wrap w-full justify-between">
        <div className="flex flex-row items-center justify-end gap-x-6 mb-2">
          <AttendanceRobot
            isAutomaticallyUpdated={
              !updatedById &&
              attendanceStatus !== CohortSessionTeacherAttendanceStatus.Unknown
            }
          />
          <div className="flex items-center">
            <AttendanceStatusInput
              attendanceRowId={id}
              status={debouncedDraftStatus}
              onChange={(status) => setDraftStatus(status)}
              attendanceStatusOptions={attendanceStatusOptions}
              resetStatus={resetStatus}
              statusConditionalElementFunc={statusConditionalElementFunc}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
