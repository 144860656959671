import { gql } from "@apollo/client";
import {
  TutorTabCohortEventTable_TutorDashboardCohortFragment,
  TutorTabCohortEventTable_TutorDashboardCohortSessionStudentAttendanceFragment,
  TutorTabCohortEventTable_TutorDashboardCohortSessionStudentGradingFragment,
  TutorTabCohortEventTable_TutorDashboardEngagementFragment,
  TutorTabCohortEventTable_TutorDashboardEventFragment,
  TutorTabCohortEventTable_TutorDashboardStudentFragment,
} from "@generated/graphql";
import { Table } from "components/shared";
import { useMemo } from "react";
import { Column } from "react-table";
import { TutorTabDashboardEventDetails } from "../../types";
import { attendanceCell, gradingCell, nameCell } from "./cells";
import { TutorTabDashboardStudentDetailsRow } from "./types";

const STUDENT_FRAGMENT = gql`
  fragment TutorTabCohortEventTable_TutorDashboardStudent on TutorDashboardStudent {
    id
    studentId
    removedAt
    ...NameCell_TutorDashboardStudent
    ...AttendanceCell_TutorDashboardStudent
  }
  ${nameCell.fragments.tutorDashboardStudent}
  ${attendanceCell.fragments.tutorDashboardStudent}
`;

const ATTENDANCE_FRAGMENT = gql`
  fragment TutorTabCohortEventTable_TutorDashboardCohortSessionStudentAttendance on TutorDashboardCohortSessionStudentAttendance {
    studentId
    ...GradingCell_TutorDashboardCohortSessionStudentAttendance
    ...AttendanceCell_TutorDashboardCohortSessionStudentAttendance
  }
  ${gradingCell.fragments.tutorDashboardCohortSessionStudentAttendance}
  ${attendanceCell.fragments.tutorDashboardCohortSessionStudentAttendance}
`;
const GRADING_FRAGMENT = gql`
  fragment TutorTabCohortEventTable_TutorDashboardCohortSessionStudentGrading on TutorDashboardCohortSessionStudentGrading {
    studentId
    ...GradingCell_TutorDashboardCohortSessionStudentGrading
  }
  ${gradingCell.fragments.tutorDashboardCohortSessionStudentGrading}
`;

TutorTabCohortEventTable.fragments = {
  tutorDashboardEvent: gql`
    fragment TutorTabCohortEventTable_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      startDateTime
      cohortId
      engagementId
      subject
      subSubject
      isCohortAssessmentDay
      isCohortNoShowDay
      isEngagementAssessmentDay
      isEngagementNoShowDay
      cohortSessionId
      cohortSession {
        id
        studentAttendanceEntries {
          id
          ...TutorTabCohortEventTable_TutorDashboardCohortSessionStudentAttendance
        }
        studentGradingEntries {
          id
          ...TutorTabCohortEventTable_TutorDashboardCohortSessionStudentGrading
        }
      }
    }
    ${ATTENDANCE_FRAGMENT}
    ${GRADING_FRAGMENT}
  `,
  tutorDashboardCohort: gql`
    fragment TutorTabCohortEventTable_TutorDashboardCohort on TutorDashboardCohort {
      id
      activeStudents {
        id
        studentId
        ...TutorTabCohortEventTable_TutorDashboardStudent
      }
    }
    ${STUDENT_FRAGMENT}
  `,
  tutorDashboardEngagement: gql`
    fragment TutorTabCohortEventTable_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      enableStudentGrading
    }
  `,
};

type EventDetails = TutorTabDashboardEventDetails<
  TutorTabCohortEventTable_TutorDashboardEventFragment,
  TutorTabCohortEventTable_TutorDashboardCohortFragment,
  TutorTabCohortEventTable_TutorDashboardEngagementFragment
>;

type RowData = TutorTabDashboardStudentDetailsRow<
  TutorTabCohortEventTable_TutorDashboardStudentFragment,
  TutorTabCohortEventTable_TutorDashboardCohortSessionStudentAttendanceFragment,
  TutorTabCohortEventTable_TutorDashboardCohortSessionStudentGradingFragment
>;

type Props = {
  eventDetails: EventDetails;
};

export function TutorTabCohortEventTable({ eventDetails }: Props) {
  const rowData = useMemo<RowData[]>(
    () =>
      (eventDetails.cohort?.activeStudents ?? []).map((student) => ({
        id: student.id, // Requirement for react-table to key the table.
        student,
        studentAttendanceEntry:
          eventDetails.cohortSession?.studentAttendanceEntries.find(
            (entry) => entry.studentId === student.studentId
          ) ?? null,
        studentGradingEntry:
          eventDetails.cohortSession?.studentGradingEntries.find(
            (entry) => entry.studentId === student.studentId
          ) ?? null,
        startDateTime: eventDetails.startDateTime,
        cohortSessionId: eventDetails?.cohortSessionId ?? "",
        cohortId: eventDetails.cohortId,
        engagementId: eventDetails.engagementId,
        subject: eventDetails.subject,
        subSubject: eventDetails.subSubject,
        isCohortAssessmentDay: eventDetails.isCohortAssessmentDay,
        isCohortNoShowDay: eventDetails.isCohortNoShowDay,
        isEngagementAssessmentDay: eventDetails.isEngagementAssessmentDay,
        isEngagementNoShowDay: eventDetails.isEngagementNoShowDay,
        enableStudentGrading: !!eventDetails.engagement?.enableStudentGrading,
      })),
    [eventDetails]
  );

  return (
    <Table
      showPagination={false}
      columns={getColumns(!!eventDetails.engagement?.enableStudentGrading)}
      className="min-h-0"
      data={rowData}
    />
  );
}

const getColumns = (enableStudentGrading: boolean): Column<RowData>[] =>
  enableStudentGrading
    ? [nameCell(), attendanceCell(), gradingCell()]
    : [nameCell(), attendanceCell()];
