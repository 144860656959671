import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditSingleStudentGradingMutation,
  EditSingleStudentGradingMutationVariables,
  GradingCell_TutorDashboardCohortSessionStudentAttendanceFragment,
  GradingCell_TutorDashboardCohortSessionStudentGradingFragment,
} from "@generated/graphql";
import { triggerErrorToast } from "components/shared";
import { GradingPanel } from "components/shared/AttendanceGrades/GradingPanel";
import {
  isNotAttended,
  negOneIsNull,
} from "components/shared/AttendanceGrades/utils";
import { CellProps, Column } from "react-table";
import { TutorTabDashboardStudentDetailsRow } from "../types";
import { noRecordBadge, noShowDayTooltip } from "./helpers";

gradingCell.fragments = {
  tutorDashboardCohortSessionStudentAttendance: gql`
    fragment GradingCell_TutorDashboardCohortSessionStudentAttendance on TutorDashboardCohortSessionStudentAttendance {
      id
      status
    }
  `,
  tutorDashboardCohortSessionStudentGrading: gql`
    fragment GradingCell_TutorDashboardCohortSessionStudentGrading on TutorDashboardCohortSessionStudentGrading {
      id
      subject
      participationGrade
      languageArtsGrade
      languageArtsAbsentFromAssessment
      readingGrade
      readingAbsentFromAssessment
      classworkGrade
      classworkAbsentFromAssessment
      ...GradingPanel_StudentGradingRecord
    }
    ${GradingPanel.fragments.tutorDashboardCohortSessionStudentGrading}
  `,
};

const EDIT_SINGLE_STUDENT_GRADING = gql`
  mutation EditSingleStudentGrading(
    $input: [EditCohortSessionStudentGradingInput!]!
  ) {
    editCohortSessionStudentGrading(input: $input) {
      id
      participationGrade
      languageArtsGrade
      languageArtsAbsentFromAssessment
      readingGrade
      readingAbsentFromAssessment
      classworkGrade
      classworkAbsentFromAssessment
    }
  }
`;

type StudentGrades =
  GradingCell_TutorDashboardCohortSessionStudentGradingFragment;

type CellData = TutorTabDashboardStudentDetailsRow<
  unknown,
  GradingCell_TutorDashboardCohortSessionStudentAttendanceFragment,
  GradingCell_TutorDashboardCohortSessionStudentGradingFragment
>;

export function gradingCell<C extends CellData>(): Column<C> {
  return {
    id: "grading",
    Header: "Grading",
    width: "!py-2 !pr-2",
    Cell: ({
      row: {
        original: {
          startDateTime,
          subject,
          isCohortNoShowDay,
          studentGradingEntry,
          isEngagementNoShowDay,
          studentAttendanceEntry,
        },
      },
    }: CellProps<C>) => {
      const [editSingleStudentGradingMutation] = useMutation<
        EditSingleStudentGradingMutation,
        EditSingleStudentGradingMutationVariables
      >(EDIT_SINGLE_STUDENT_GRADING, {
        update(cache, { data }) {
          if (data) {
            const updatedGrading = data.editCohortSessionStudentGrading[0];
            cache.modify({
              id: `TutorDashboardCohortSessionStudentGrading:${updatedGrading.id}`,
              fields: {
                participationGrade: () =>
                  updatedGrading.participationGrade ?? null,
                languageArtsGrade: () =>
                  updatedGrading.languageArtsGrade ?? null,
                languageArtsAbsentFromAssessment: () =>
                  updatedGrading.languageArtsAbsentFromAssessment,
                readingGrade: () => updatedGrading.readingGrade ?? null,
                readingAbsentFromAssessment: () =>
                  updatedGrading.readingAbsentFromAssessment,
                classworkGrade: () => updatedGrading.classworkGrade ?? null,
                classworkAbsentFromAssessment: () =>
                  updatedGrading.classworkAbsentFromAssessment,
              },
            });
          }
        },
        onError: (error: ApolloError) =>
          triggerErrorToast({
            message: "Looks like something went wrong.",
            sub: "We weren't able to update this student's grades successfully.",
            log: error,
          }),
      });

      if (!studentGradingEntry || !studentAttendanceEntry) {
        if (startDateTime > new Date()) return null;
        return noRecordBadge("grading");
      }

      const updateStudentGrade = async (grades: StudentGrades) => {
        await editSingleStudentGradingMutation({
          variables: {
            input: [
              {
                id: grades.id,
                participationGrade: negOneIsNull(grades.participationGrade),
                languageArtsGrade: negOneIsNull(grades.languageArtsGrade),
                languageArtsAbsentFromAssessment:
                  grades.languageArtsAbsentFromAssessment,
                readingGrade: negOneIsNull(grades.readingGrade),
                readingAbsentFromAssessment: grades.readingAbsentFromAssessment,
                classworkGrade: negOneIsNull(grades.classworkGrade),
                classworkAbsentFromAssessment:
                  grades.classworkAbsentFromAssessment,
              },
            ],
          },
        });
      };

      return isEngagementNoShowDay || isCohortNoShowDay ? (
        noShowDayTooltip(isEngagementNoShowDay)
      ) : (
        <div className="w-full">
          {subject && (
            <GradingPanel
              subject={subject}
              studentGrades={studentGradingEntry}
              disableGrading={isNotAttended(studentAttendanceEntry.status)}
              updateStudentGrade={updateStudentGrade}
            />
          )}
        </div>
      );
    },
  };
}
