import { gql } from "@apollo/client";
import { RoleCell_DashboardStaffFragment } from "@generated/graphql";

import { CellProps, Column } from "react-table";

roleCell.fragments = {
  staff: gql`
    fragment RoleCell_DashboardStaff on DashboardStaff {
      role
    }
  `,
};

export function roleCell<
  Fragment extends RoleCell_DashboardStaffFragment,
>(): Column<Fragment> {
  return {
    Header: "Role",
    accessor: "role",
    Cell: ({ row: { original } }: CellProps<Fragment>) => {
      return <p>{original.role}</p>;
    },
  };
}
