import { gql } from "@apollo/client";
import {
  InstructionalSupportTab_TutorDashboardCohortFragment,
  InstructionalSupportTab_TutorDashboardEngagementFragment,
  InstructionalSupportTab_TutorDashboardEventFragment,
} from "@generated/graphql";
import { IndexMap } from "@utils/indexMap";
import { useInterval } from "@utils/useInterval";
import { Container, Table } from "components/shared";
import { TT_DASH_SORT_ORDER } from "components/shared/AttendanceGrades/constants";
import { AttendanceDataProvider } from "contexts/AttendanceDataProvider";
import { useMemo, useState } from "react";
import { Column } from "react-table";
import { DEFAULT_TUTOR_DASHBOARD_REFETCH_INTERVAL } from "../../constants";
import { getDashboardEmptyContainer } from "../../helpers";
import { cohortNameCell } from "./cells/cohortNameCell";
import { engagementNameCell } from "./cells/engagementNameCell";
import { hostPresenceCell } from "./cells/hostPresenceCell";
import { statusCell } from "./cells/statusCell";
import { studentCountCell } from "./cells/studentCountCell";
import { InstructionalSupportTabDashboardEventDetails } from "./types";
import { instructionalSupportTabDashboardEventDetailsBuilder } from "./utils";

InstructionalSupportTab.fragments = {
  tutorDashboardEvent: gql`
    fragment InstructionalSupportTab_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      startDateTime
      cohortId
      engagementId
      ...InstructionalSupportTabDashboardEventDetailsBuilder_TutorDashboardEvent
      ...CohortNameCell_TutorDashboardEvent
      ...EngagementNameCell_TutorDashboardEvent
      ...HostPresenceCell_TutorDashboardEvent
      ...StatusCell_TutorDashboardEvent
    }
    ${instructionalSupportTabDashboardEventDetailsBuilder.fragments
      .tutorDashboardEvent}
    ${cohortNameCell.fragments.tutorDashboardEvent}
    ${engagementNameCell.fragments.tutorDashboardEvent}
    ${hostPresenceCell.fragments.tutorDashboardEvent}
    ${statusCell.fragments.tutorDashboardEvent}
  `,
  tutorDashboardCohort: gql`
    fragment InstructionalSupportTab_TutorDashboardCohort on TutorDashboardCohort {
      id
      ...CohortNameCell_TutorDashboardCohort
      ...StatusCell_TutorDashboardCohort
      ...StudentCountCell_TutorDashboardCohort
    }
    ${cohortNameCell.fragments.tutorDashboardCohort}
    ${statusCell.fragments.tutorDashboardCohort}
    ${studentCountCell.fragments.tutorDashboardCohort}
  `,
  tutorDashboardEngagement: gql`
    fragment InstructionalSupportTab_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      ...EngagementNameCell_TutorDashboardEngagement
      ...HostPresenceCell_TutorDashboardEngagement
      ...StatusCell_TutorDashboardEngagement
    }
    ${engagementNameCell.fragments.tutorDashboardEngagement}
    ${hostPresenceCell.fragments.tutorDashboardEngagement}
    ${statusCell.fragments.tutorDashboardEngagement}
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  InstructionalSupportTab_TutorDashboardEventFragment,
  InstructionalSupportTab_TutorDashboardCohortFragment,
  InstructionalSupportTab_TutorDashboardEngagementFragment
>;

type Props = {
  isDashboardEvents: InstructionalSupportTab_TutorDashboardEventFragment[];
  cohorts: InstructionalSupportTab_TutorDashboardCohortFragment[];
  engagements: InstructionalSupportTab_TutorDashboardEngagementFragment[];
  loading: boolean;
};

export function InstructionalSupportTab({
  isDashboardEvents,
  cohorts,
  engagements,
  loading,
}: Props) {
  const [currentDate, setCurrentDate] = useState(new Date());
  useInterval(
    () => setCurrentDate(new Date()),
    DEFAULT_TUTOR_DASHBOARD_REFETCH_INTERVAL
  );

  const cohortsMap = useMemo(
    () => new IndexMap(cohorts, ({ id }) => id),
    [cohorts]
  );
  const engagementsMap = useMemo(
    () => new IndexMap(engagements, ({ id }) => id),
    [engagements]
  );

  const cohortRowData = useMemo<EventDetails[]>(
    () =>
      isDashboardEvents
        .map((dashboardEvent) =>
          instructionalSupportTabDashboardEventDetailsBuilder(
            dashboardEvent,
            cohortsMap.get(dashboardEvent.cohortId),
            engagementsMap.get(dashboardEvent.engagementId),
            currentDate
          )
        )
        .sort(
          (a, b) =>
            TT_DASH_SORT_ORDER.indexOf(a.scheduleStatus) -
            TT_DASH_SORT_ORDER.indexOf(b.scheduleStatus)
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDashboardEvents, currentDate]
  );

  return (
    <AttendanceDataProvider>
      <Container flex className="justify-center items-center min-h-[150px]">
        {cohortRowData && cohortRowData.length > 0 ? (
          <Table
            showPagination={false}
            columns={getColumns()}
            className="min-h-0"
            loading={loading}
            data={cohortRowData}
          />
        ) : (
          getDashboardEmptyContainer(
            "an Instructional Support (IS) or substitute",
            loading
          )
        )}
      </Container>
    </AttendanceDataProvider>
  );
}

const getColumns = (): Column<EventDetails>[] => [
  cohortNameCell(),
  engagementNameCell(),
  studentCountCell(),
  hostPresenceCell(),
  statusCell(),
];
