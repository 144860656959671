import { gql } from "@apollo/client";
import {
  StatusCell_TutorDashboardCohortFragment,
  StatusCell_TutorDashboardEngagementFragment,
  StatusCell_TutorDashboardEventFragment,
} from "@generated/graphql";
import { getOrigin } from "@utils/browser";
import { getHostShortMeetingLink } from "@utils/roomUrls";
import clsx from "clsx";
import { Button, Link } from "components/shared";
import {
  format,
  formatDuration,
  intervalToDuration,
  isToday,
  isTomorrow,
  isYesterday,
} from "date-fns";
import { CellProps, Column } from "react-table";
import { EventScheduleStatus } from "types/events";
import { InstructionalSupportTabDashboardEventDetails } from "../types";

statusCell.fragments = {
  tutorDashboardEvent: gql`
    fragment StatusCell_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      startDateTime
      endDateTime
      durationMinutes
    }
  `,
  tutorDashboardCohort: gql`
    fragment StatusCell_TutorDashboardCohort on TutorDashboardCohort {
      id
      publicReferenceId
    }
  `,
  tutorDashboardEngagement: gql`
    fragment StatusCell_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      videoProvider
    }
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  StatusCell_TutorDashboardEventFragment,
  StatusCell_TutorDashboardCohortFragment,
  StatusCell_TutorDashboardEngagementFragment
>;

export function statusCell<D extends EventDetails>(): Column<D> {
  return {
    id: "statusCell",
    Header: "Status",
    Cell: ({
      row: {
        original: {
          startDateTime,
          endDateTime,
          durationMinutes,
          cohort,
          engagement,
          scheduleStatus,
          minutesElapsed,
          minutesRemaining,
        },
      },
    }: CellProps<D>) => {
      const isOngoing = scheduleStatus === EventScheduleStatus.ONGOING;
      const isPast = scheduleStatus === EventScheduleStatus.CONCLUDED;
      const isUpcoming = scheduleStatus === EventScheduleStatus.UPCOMING;

      const sessionStartDateTime = new Date(startDateTime);
      const sessionEndDateTime = new Date(endDateTime);
      const startTimeFormatted = format(sessionStartDateTime, "h:mm a");
      const endTimeFormatted = format(sessionEndDateTime, "h:mm	a");

      const timeUntilStart = intervalToDuration({
        start: new Date(),
        end: startDateTime,
      });

      const formattedTimeUntilStart = formatDuration(timeUntilStart, {
        format: ["hours", "minutes"],
      });

      const getRelativeDayName = isToday(startDateTime)
        ? "Today"
        : isYesterday(startDateTime)
        ? "Yesterday"
        : isTomorrow(startDateTime)
        ? "Tomorrow"
        : format(startDateTime, "EEEE, MM/dd/yyyy");

      return (
        <div className="flex flex-col justify-between items-center gap-5 lg:flex-row">
          <div className="flex flex-col text-sm font-medium gap-1 ">
            <div className="relative flex flex-row items-center w-auto gap-3">
              {isOngoing && (
                <div className="absolute top-[4px] left-[-18px] w-3 h-3 rounded-full bg-rose-500 animate-bounce" />
              )}
              <div
                className={clsx(
                  "font-bold",
                  isOngoing && "text-rose-500",
                  isPast && "text-gray-800",
                  isUpcoming && "text-indigo-500"
                )}
              >
                {isOngoing &&
                  `Ongoing, ${minutesElapsed} minutes elapsed; ${minutesRemaining} remaining`}
                {isPast && "This session is now over."}
                {isUpcoming && `Upcoming in ${formattedTimeUntilStart}`}
              </div>
            </div>
            {`${startTimeFormatted} - ${endTimeFormatted} (${durationMinutes} min) ${getRelativeDayName}`}
          </div>
          <div className="relative min-w-[120px]">
            <Link
              href={getHostShortMeetingLink(
                getOrigin(),
                cohort?.publicReferenceId ?? "UNKNOWN_COHORT",
                engagement?.videoProvider
              )}
              target="_blank"
            >
              <Button className="h-10 leading-3" theme="primary">
                Join Meeting
              </Button>
            </Link>
          </div>
        </div>
      );
    },
  };
}
