import { Icon, Tooltip } from "components/shared";
import { useId } from "react";
import { StatusConditionalElementFunc } from "sections/EngagementsDashboard/types";
import { AttendanceStatusOption as AttendanceStatusOptionInterface } from "../constants";

interface Props<
  AttendanceStatus extends string,
  AttendanceStatusOption extends
    AttendanceStatusOptionInterface<AttendanceStatus>,
> {
  disabled?: boolean;
  attendanceRowId: string;
  status: AttendanceStatus;
  resetStatus?: AttendanceStatus;
  attendanceStatusOptions: AttendanceStatusOption[];
  onChange: (status: AttendanceStatus) => void;
  statusConditionalElementFunc?: StatusConditionalElementFunc<AttendanceStatus>;
}

export function AttendanceStatusInput<
  AttendanceStatus extends string,
  AttendanceStatusOption extends
    AttendanceStatusOptionInterface<AttendanceStatus>,
>({
  status,
  disabled,
  resetStatus,
  attendanceRowId,
  attendanceStatusOptions,
  onChange,
  statusConditionalElementFunc,
}: Props<AttendanceStatus, AttendanceStatusOption>) {
  const id = useId();

  return (
    <fieldset>
      <legend className="sr-only">Attendance Status</legend>
      <div className="flex gap-4 items-center">
        {attendanceStatusOptions.map((attendanceOption) => {
          const inputId = `${attendanceRowId}-${attendanceOption}-${id}`;
          const inputName = `status-for-${attendanceRowId}-${id}`;

          return (
            <div
              key={attendanceOption.status}
              className="flex items-center gap-x-2 flex-nowrap cursor-pointer"
              onClick={() =>
                !disabled &&
                onChange(attendanceOption.status as AttendanceStatus)
              }
            >
              <input
                id={inputId}
                type="radio"
                name={inputName}
                value={attendanceOption.status}
                checked={attendanceOption.status === status}
                onChange={(event) =>
                  onChange(event.target.value as AttendanceStatus)
                }
                disabled={disabled}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
              />
              <label
                htmlFor={inputId}
                className="block text-xs font-medium text-gray-700 cursor-pointer"
              >
                {attendanceOption.title}
              </label>
            </div>
          );
        })}

        {statusConditionalElementFunc?.(status)}

        {resetStatus !== undefined && (
          <Tooltip content="Click to deselect attendance choice.">
            <div
              className="cursor-pointer justify-center items-center pr-1 -ml-1"
              onClick={() => onChange(resetStatus)}
            >
              <Icon icon="undoCircle" size={4} color=" text-gray-700" />
              <span className="sr-only">Clear</span>
            </div>
          </Tooltip>
        )}
      </div>
    </fieldset>
  );
}
