import { gql } from "@apollo/client";
import {
  AttendanceCell_DashboardStaffFragment,
  DashboardAssignmentRole,
  DashboardAttendanceStatus,
} from "@generated/graphql";
import { AttendanceRobot, Tooltip } from "components/shared";
import { FaUserEdit } from "react-icons/fa";
import { CellProps, Column } from "react-table";

attendanceCell.fragments = {
  staff: gql`
    fragment AttendanceCell_DashboardStaff on DashboardStaff {
      name
      role
      attendance {
        id
        status
        automaticallyUpdated
      }
    }
  `,
};

export function attendanceCell<
  Fragment extends AttendanceCell_DashboardStaffFragment,
>(
  openAttendanceModal: (value: boolean) => void,
  setAttendanceId: (id: string) => void,
  setTeacherName: (teacherName: string) => void
): Column<Fragment> {
  return {
    Header: "Attendance Status",
    accessor: "attendance",
    Cell: ({ row: { original } }: CellProps<Fragment>) => {
      const attendanceButton =
        original.attendance.id &&
        original.role === DashboardAssignmentRole.Tt ? (
          <Tooltip
            content="Take attendance"
            tooltipProps={{ place: "right" }}
            className="flex items-center"
          >
            <FaUserEdit
              className="h-6 w-6 text-gray-600"
              aria-hidden="true"
              onClick={() => {
                if (!original.attendance.id) return;
                setAttendanceId(original.attendance.id);
                setTeacherName(original.name);
                openAttendanceModal(true);
              }}
            />
          </Tooltip>
        ) : null;

      return (
        <div className="flex flex-row items-center gap-x-4">
          <Tooltip
            content={AttendanceStatusColors[original.attendance.status].content}
            tooltipProps={{ place: "left" }}
            className="flex items-center ml-5"
          >
            <div
              className={`w-3 h-3 auto rounded-full cursor-pointer ${
                AttendanceStatusColors[original.attendance.status].color
              } `}
            />
          </Tooltip>
          <AttendanceRobot
            isAutomaticallyUpdated={!!original.attendance.automaticallyUpdated}
          />
          {attendanceButton}
        </div>
      );
    },
  };
}

const AttendanceStatusColors = {
  [DashboardAttendanceStatus.Unknown]: {
    color: "bg-gray-400",
    content: "The attendance was not marked",
  },
  [DashboardAttendanceStatus.Present]: {
    color: "bg-emerald-500",
    content: "The attendance was marked as PRESENT",
  },
  [DashboardAttendanceStatus.Absent]: {
    color: "bg-rose-500",
    content: "The attendance was marked as ABSENT",
  },
};
